/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('searchQuery', [
    'localCache',
    'cacheKeys',
    function (localCache, cacheKeys) {
      var SearchQuery = localCache.get(cacheKeys.searchQuery);

      if (isEmpty(SearchQuery)) {
        SearchQuery = {
          monthlyRent: {},
          bedrooms: { low: 0 },
          bathrooms: { low: 0 },
          size: { low: 0 },
          pets: { large_dogs: false, cats: false, small_dogs: false },
          propertyTypes: {
            house: false,
            apartment: false,
            townhouse: false,
            sublets: false
          },
          area: {
            type: '',
            shape: ''
          }
        };
      }

      return SearchQuery;
    }
  ]);

  app.factory('suggestionContext', [
    'localCache',
    'cacheKeys',
    function (localCache, cacheKeys) {
      var SuggestionContext = localCache.get(cacheKeys.autoSuggestSelection);

      if (isEmpty(SuggestionContext)) {
        SuggestionContext = {
          suggestion: ''
        };
      }

      return SuggestionContext;
    }
  ]);

  app.factory('pagingParams', function () {
    return {
      start: 0,
      size: 500
    };
  });

  app.factory('searchApi', [
    'apiBase',
    function (apiBase) {
      return {
        propertySearch: function (searchQuery, pagingParams, canceler) {
          if (!searchQuery) {
            throw 'query is required';
          }

          // Remove empty low monthlyRent value
          if (
            !isUndefined(searchQuery.monthlyRent.low) &&
            searchQuery.monthlyRent.low.length <= 0
          ) {
            delete searchQuery.monthlyRent.low;
          }

          // Remove empty high monthlyRent value
          if (
            !isUndefined(searchQuery.monthlyRent.high) &&
            searchQuery.monthlyRent.high.length <= 0
          ) {
            delete searchQuery.monthlyRent.high;
          }

          var options = {};
          options.params = pagingParams;
          options.timeout = canceler ? canceler.promise : null;

          return apiBase.post('/search', searchQuery, options);
        },
        autoSuggest: function (query) {
          if (!query) {
            throw 'query is required';
          }
          if (query.length < 3) {
            throw 'query string must be at least 3 characters';
          }

          return apiBase.get('/search/suggest?query=' + query);
        },
        suggestionContext: function (cityId) {
          return apiBase.get('/search/context/' + cityId);
        },
        getShape: function (type, shapeId) {
          return apiBase.get('/search/shapes/' + type + '/' + shapeId, {
            cache: true
          });
        },
        streamSearch: function (queryString, scope, field) {
          var payload = {
            query_string: queryString,
            scope: scope,
            field: field
          };
          return apiBase.post('/search/streams', payload);
        },
        prospectSearch: function (
          queryString,
          scope,
          field,
          personType,
          useExactHits
        ) {
          var payload = {
            query_string: queryString,
            scope: scope,
            field: field,
            person_type: personType,
            use_exact_hits: useExactHits
          };

          return apiBase.post('/search/people', payload);
        },
        prospectSearchByProperty: function (propertyId, queryString, field) {
          var payload = {
            query_string: queryString,
            scope: 'property',
            person_type: 'prospect',
            use_exact_hits: true,
            property_id: propertyId
          };

          if (field) {
            payload.field = field;
          }

          return apiBase.post('/search/people', payload);
        }
      };
    }
  ]);
})();
