/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import isInteger from 'lodash/isInteger';

(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('communityLeasingEditor', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/community/directives/community-leasing-editor.html',
      controller: [
        '$scope',
        '$rootScope',
        function ($scope, $rootScope) {
          $scope.availableDatePickerOpen = false;
          $scope.leaseMonthsInvalid = true;
          $scope.upchargeInvalid = true;
          $scope.upchargesEnabled = $rootScope.featureFlags.QUOTE_UPCHARGES;
          $scope.isReadOnly = $rootScope.featureFlags.ADMIN_PHOTOS_MIGRATION;

          $scope.setLeaseMonthsDirty = function () {
            $scope.leaseMonthsDirty = true;
          };

          $scope.$watch(
            'community.leasing.terms.leaseLengths',
            function (leaseLengths) {
              $scope.leaseMonthsInvalid = _hasNoTruthyValue(leaseLengths);
              $scope.upchargeInvalid = _hasInvalidUpchargeAmount(leaseLengths);
            },
            true
          );

          function _hasNoTruthyValue(toCheck) {
            return isEmpty(filter(toCheck, 'isAvailable'));
          }

          function _hasInvalidUpchargeAmount(toCheck) {
            // look for any non-integer values entered for upcharge amounts
            // in order to disable saving of community if invalid entries found
            if (!$scope.upchargesEnabled) {
              return false;
            }
            const filteredLeaseLengths = filter(
              toCheck,
              function (leaseLength) {
                return leaseLength.hasOwnProperty('upchargeAmount');
              }
            );
            return !every(filteredLeaseLengths, function (leaseLength) {
              return isInteger(leaseLength.upchargeAmount);
            });
          }
        }
      ]
    };
  });
})();
